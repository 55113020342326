:root {
  --primary-padding: 10px;
  --primary-border: 1px solid #ddd;
  --primary-font-size: 18px;
  --secondary-padding: 20px;
  --border-radius: 10px;
  --error-color: red;
  --highlight-color: #90EE90;
  --table-bg-color: #f2f2f2;
  --input-height: 30px;
  --header-height: 60px;
  --icon-size: 60px;
}

/* Block: Header */
.Header {
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: var(--primary-padding);
  background-color: var(--table-bg-color); 
  margin-bottom: var(--primary-padding);
}

.Header-icon {
  width: var(--icon-size);
  height: var(--icon-size);
  margin-right: var(--primary-padding);
}

/* Base styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Block: App */
.App {
  padding: var(--primary-padding);
  max-width: 500px;
  margin: auto;
  box-sizing: border-box;
}

/* Block: Table Container */
.Table-container {
  overflow-x: auto;
}

/* Block: Table */
.Table {
  width: 100%;
  margin-top: var(--secondary-padding);
  margin-bottom: var(--secondary-padding);
  border-collapse: collapse;
  background-color: var(--table-bg-color);
}

.Table th {
  text-align: center;
  padding: 8px;
  font-size: calc(var(--primary-font-size) - 4px);
}

/* Block: Table Row */
.TableRow--selected {
  background-color: var(--highlight-color);
}

/* Block: Error */
.error {
  color: var(--error-color);
  margin-top: var(--secondary-padding);
  margin-bottom: var(--primary-padding);
  font-size: var(--primary-font-size);
  text-align: center;
}

/* Block: Input Group */
.input-group {
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin-bottom: var(--primary-padding);
}

.input-group label {
  text-align: left;
  margin-right: var(--primary-padding);
}

/* Block: Section Break */
.section-break {
  border: 0;
  border-top: var(--primary-border);
  margin: var(--secondary-padding) 0;
}

.center-text {
  text-align: center;
}

/* Responsive Design */
@media (max-width: 400px) {
  .App {
      padding: var(--secondary-padding);
  }

  input, button {
      border-radius: var(--border-radius);
  }

  input {
    font-size: 18px;  /* or higher */
    height: var(--input-height);
  }

  .button {
      font-size: calc(var(--primary-font-size) + 4px); /* Adjusted font size */
  }

}
